<template>
  <div class='bg-gray-900'>
    <div v-if='showingMessageBox'
         class='absolute text-sm text-center rounded z-20 w-4/5 bg-gray-100 p-4 lg:p-8 border'>
      <div class='p-2 lg:p-8 text-center leading-normal whitespace-pre-line'>
        {{ selectedSessionConfigAttendingMessage }}
      </div>
      <button
        class='h-12 pl-12 pr-10 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200 flex flex-row items-center mx-auto gap-x-2'
        :style='startLiveButtonStyle'
        @click='playLiveVideo'>
        {{ selectedSessionConfigAttendingButtonText }}
        <play-icon/>
      </button>
    </div>
    <button v-if='isVideoMuted'
         class='absolute text-center rounded z-20 top-1 right-1 text-gray-100 bg-gray-900 px-4 py-1'
         :style='startLiveButtonStyle'
         @click='videoSoundOn'>
      Sound on <volume-up-icon class='inline-block mb-1' />
    </button>
    <iframe 
      id='videoPlayer'
      name='videoPlayer'
      width='100%'
      height='100%'
      :key='contentID'
      :src='selectedSessionCurrentContentSourceUrl'
      frameborder='0'
      allowfullscreen
      allow='autoplay;fullscreen;'>
    </iframe>
  </div>
</template>

<script src= "//play.acs.wecandeo.com/html/utils/iframeAPI.js"></script>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import {PlayIcon, VolumeUpIcon}               from '@vue-hero-icons/outline'
import dayjs                    from 'dayjs'
import WatchedVideoCacheHelpers from '@/utils/watched-video-cache-helpers'
import { createStoreon }        from 'storeon'
import { crossTab }             from '@storeon/crosstab'

export default {
  name: 'LiveVideoContentWecandeo',
  props: [
    'watermarkText',
    'watermarkHelperText'
  ],
  components: {
    PlayIcon,
    VolumeUpIcon,
  },
  data () {
    return {
      getLiveVideoPlayedTimes: null,
      showingMessageBox: false,
      liveVideoPlayer: null,
      playStartTime: null,
      videoTracker: null,
      storeViewingVideoCount: null,
      increaseViewingVideoCount: null,
      playedTimes: null,
      isVideoMuted: false,
      isVideoReady: false,
    }
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
      'showingEventId',
    ]),
    ...mapGetters('sessions', [
      'selectedSessionCurrentContent',
      'selectedSessionCurrentContentSourceUrl',
      'selectedSessionConfigAttendingMessage',
      'selectedSessionConfigAttendingButtonText',
      'selectedSessionConfigAutoplay',
    ]),
    cacheKey () {
      return WatchedVideoCacheHelpers.encodeCacheKey({
        eventID: this.showingEventId,
        sessionID: this.sessionID,
        contentID: this.contentID,
        startTime: this.playStartTime
      })
    },
    trackWatchedVideoParams () {
      return {
        event_id: this.showingEventId,
        session_id: this.sessionID,
        content_id: this.contentID,
        video_player: 'wecandeo',
        start_time: this.playStartTime,
        played_times: this.playedTimes
      }
    },
    contentID () {
      return this.selectedSessionCurrentContent.id
    },
    sessionID () {
      return parseInt(this.$route.query.session_id)
    },
    startLiveButtonStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
    attendingMessageBoxClass () {
      return this.selectedSessionConfigAttendingMessage ? 'w-full bg-gray-100 p-4 lg:p-8' : ''
    },
  },
  methods: {
    videoSoundOn () {
      this.liveVideoPlayer.setVolume(80)
    },
    ...mapActions('sessions', [
      'startWatchSession',
      'stopWatchSession',
    ]),
    ...mapActions('watchedVideos', [
      'trackWatchedVideo',
    ]),
    ...mapMutations([
      'setIsFullscreenVideo',
    ]),
    ...mapActions('userActions', [
      'createUserAction'
    ]),
    playLiveVideo () {
      if (this.isVideoReady) {
        if (this.$route.query.autoplay) {
          let query = Object.assign({}, this.$route.query)
          delete query.autoplay
          this.$router.replace({ query })
        }
        this.initStoreViewingVideoCount()
        this.playStartTime = dayjs().format()
        this.showingMessageBox = false
        this.getLiveVideoPlayedTimes = setInterval(() => {
          let playedArray = []
          let playedData = [0, this.liveVideoPlayer.getPosition()]
          playedArray.push(playedData)
          WatchedVideoCacheHelpers.queueCache({cacheKey: this.cacheKey, playedArray: playedArray})
          this.playedTimes = playedArray //update trackWatchedVideoParams played_times every 5 seconds
        }, 5000)
        this.videoTracker = setInterval(this.submitTrackWatchedVideo, 30000)
        this.$emit('enter-live-session')
        this.createUserAction({
          userActionName: 'click_live_session_video_start_button',
          userActionData: {
            event_id: this.showingEventId,
            session_id: this.sessionID,
            content_id: this.contentID,
            video_player: 'wecandeo'
          }
        })
        this.liveVideoPlayer.play()
      } else {
        this.$modal.show('video-troubleshoot-modal')
      }
    },
    submitTrackWatchedVideo () {
      // this.liveVideoPlayer.error(3) // to trigger decode error manually
      this.trackWatchedVideo(this.trackWatchedVideoParams)
    },
    initStoreViewingVideoCount () {
      this.increaseViewingVideoCount = store => {
        store.on('@init', () => ({ viewingVideoCount: 0 }))
        store.on('increase', ({ viewingVideoCount }) => ({ viewingVideoCount: viewingVideoCount + 1 }))
      }
      this.storeViewingVideoCount = createStoreon([
        this.increaseViewingVideoCount,
        crossTab()
      ])
      this.storeViewingVideoCount.dispatch('increase')
      this.storeViewingVideoCount.on('@changed', (store) => {
        if (store.viewingVideoCount > 1) {
          if (this.liveVideoPlayer) {
            this.liveVideoPlayer.destroy()
          }
          this.$alert('You have started a video on another tab. This tab will be redirected to the programs page after 5 seconds', {
            confirmButtonText: 'Close',
            type: 'warning'
          })
          setTimeout(() => {
            window.open('Programs', '_self')
          }, 5000)
        }
      })
    },
    fullscreenUpdate () {
      this.setIsFullscreenVideo(this.liveVideoPlayer.getFullscreen())
    },
    initLiveVideoPlayer () {
      var iframe = document.getElementById('videoPlayer');
      var contents = iframe.contentWindow || iframe.contentDocument;
      this.liveVideoPlayer = new smIframeAPI(contents)
      this.liveVideoPlayer.setName('videoPlayer')

      this.liveVideoPlayer.onEvent(smIframeEvent.PLAY, () => {
        console.log('play')
        this.startWatchSession()
      })
      this.liveVideoPlayer.onEvent(smIframeEvent.PAUSE, () => {
        console.log('pause')
        this.stopWatchSession()
      })
      this.liveVideoPlayer.onEvent(smIframeEvent.ERROR, (error) => {
        console.error('error', error)
        this.promptRefreshPage()
      })
      this.liveVideoPlayer.onEvent(smIframeEvent.FULLSCREEN, () => {
        console.log('fullscreen')
        this.fullscreenUpdate()
      })
      this.liveVideoPlayer.onEvent(smIframeEvent.READY, () => {
        console.log('ready')
        this.showingMessageBox = true
        this.isVideoReady = true
        if (this.$route.query.autoplay && this.selectedSessionConfigAutoplay && this.isVideoReady) {
          this.liveVideoPlayer.setMuted(true)
          this.playLiveVideo()
        }
      })
      this.liveVideoPlayer.onEvent(smIframeEvent.VOLUME, () => {
        console.log('volume')
        this.isVideoMuted = this.liveVideoPlayer.getMuted()
      })
    },
    promptRefreshPage () {
      this.$confirm('There was a problem with the video. Please try refreshing the page.','Notice', {
        confirmButtonText: 'Refresh',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.$router.go(0)
      })
    },
  },
  beforeDestroy () {
    clearInterval(this.getLiveVideoPlayedTimes)
    clearInterval(this.videoTracker)
    this.stopWatchSession()
    if (this.liveVideoPlayer) {
      this.liveVideoPlayer.destroy()
    }
  },
  mounted () {
    this.initLiveVideoPlayer()
  }
}
</script>
